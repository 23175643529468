<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/product/yyz1.png" alt="" srcset="" class="imgs1">
        <img src="../../assets/images/product/yyz2.png" alt="" srcset="" class="imgs2">
        <img src="../../assets/images/product/yyz3.png" alt="" srcset="" class="imgs3">
        <img src="../../assets/images/wenli-3.png" alt="" srcset="" class="wl3">
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    // display: inline-block;
    // width: 100%;
    .imgs1{
        width: 1644px;
        height: 514px;
        margin-top: 104px;
        margin-left: 138px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs2{
        width: 1920px;
        height: 1098px;
        margin-top: 204px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs3{
        width: 1641px;
        height: 975px;
        margin-top: 84px;
        margin-left: 138px;
        margin-bottom: 73px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .wl3{
        width: 1146px;
        height: 1043px;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>